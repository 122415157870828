import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-toggle"
export default class extends Controller {
  static classes = ["changing"]   //name of the variable
  static targets = ["content"]

  connect() {
    // console.log("hello from scroll toggle")
    this.class = this.hasChangingClass ? this.changingClass : 'd-none';
    this.oldScrollPosition = window.scrollY;
    // console.log(this.oldScrollPosition);
  }

  toggleContent(event) {

    let scrollDistance = window.scrollY - this.oldScrollPosition;
    if (scrollDistance > 20) {
      this.contentTargets.forEach((t) => t.classList.add(this.class));
    } else if (scrollDistance < -20) {
      this.contentTargets.forEach((t) => t.classList.remove(this.class));
    }
    this.oldScrollPosition = window.scrollY
  }

}
